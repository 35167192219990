// @flow

import React from "react";
import { graphql } from "gatsby";

import { Page } from "../components";

/**
 * Query for the homepage
 */
export const query = graphql`
    {
        site {
            siteMetadata {
                title
            }
        }
        page: contentfulPage(homepage: { eq: true }) {
            ...PageContent
        }
    }
`;

const IndexPage = ({ data: { page, site } }: *) => (
    <Page page={page} site={site} />
);

/**
 * Homepage
 */
export default IndexPage;
